import * as t from '../types';
import axios from './axios-instance-config';
import {
  SEARCH_FILTER_API,
  GET_ADVANCED_FILTERS_API,
  GET_DISCOUNTED_DEALS_API,
  GET_BREADCRUMS_API,
  USER_ALL_WISHLIST,
  DISCOUNT_DEAL_SEARCH_FILTER,
} from '../../config/EndpointHelper';
import { API_ROOT } from '../../config';
import DataHelper from '../../config/DataHelper';
import { headers } from '../../config/HeaderHelper';

function resolveSearchType(category) {
  if (category === 'discounted-deals') {
    return {
      discountdeals: 1,
    };
  } else if (category === 'deal-of-the-week') {
    return {
      dealofweek: 1,
    };
  }
}

function resolveSearchEndpoint(category) {
  return category === 'discounted-deals'
    ? DISCOUNT_DEAL_SEARCH_FILTER
    : SEARCH_FILTER_API;
}

export const getSearchData = props => async dispatch => {
  try {
    dispatch({
      type: t.SEARCH_PAGE_LOADING,
      meta: { reducer: 'searchReducer' },
      payload: true,
    });

    props = { ...props, ...resolveSearchType(props.category) };
    props['location_id'] =
      (props['sublocation_id'] ?? []).length > 0 ? [] : props['location_id'];
    const searchEndpoint = resolveSearchEndpoint(props.category);
    const apiResponse = await axios
      .post(API_ROOT + searchEndpoint, DataHelper(props), {
        headers: headers,
      })
      .then(res => {
        dispatch({
          type: props.more_data ? t.MORE_SEARCH_DATA : t.GET_SEARCH_DATA,
          meta: { reducer: 'searchReducer' },
          payload: {
            data: res.data.data,
            nearby: res.data.nearby ?? [],
            total_pages: res.data.pageCount ?? 0,
            total_item_count: res.data.count ?? 0,
            bread_crum: res.data.bread_crum ?? [],
            pageMeta: {
              title: res.data.title ?? '',
              description: res.data.description ?? '',
              // title: res.data.p_title ?? res.data.title ?? '',
              // description: res.data.p_description ?? res.data.description ?? '',
            },
          },
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'searchReducer' },
          payload: err.response.data.message,
        });
      });
    dispatch({
      type: t.SEARCH_PAGE_LOADING,
      meta: { reducer: 'searchReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.SEARCH_PAGE_LOADING,
      meta: { reducer: 'searchReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'searchReducer' },
      payload: error,
    });
  }
};

export const updateSearchData = props => async dispatch => {
  try {
    dispatch({
      type: t.UPDATE_SEARCH_DATA,
      meta: { reducer: 'searchReducer' },
      payload: props,
    });
  } catch (error) {
    dispatch({
      type: t.SEARCH_PAGE_LOADING,
      meta: { reducer: 'searchReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'searchReducer' },
      payload: error,
    });
  }
};

export const getAdvancedFilters = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'advancedFiltersReducer' },
      payload: true,
    });
    let getAdvanceFilter = API_ROOT + GET_ADVANCED_FILTERS_API;
    getAdvanceFilter =
      props === 'rent'
        ? `${API_ROOT}${GET_ADVANCED_FILTERS_API}?module_type=${props}`
        : getAdvanceFilter;
    const apiResponse = await axios
      .get(API_ROOT + GET_ADVANCED_FILTERS_API, { params: props })
      .then(res => {
        dispatch({
          type: t.GET_ADVANCED_FILTERS,
          meta: { reducer: 'advancedFiltersReducer' },
          payload: res.data.data,
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'advancedFiltersReducer' },
          payload: err.response.data.message,
        });
      });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'advancedFiltersReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'advancedFiltersReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'advancedFiltersReducer' },
      payload: error,
    });
  }
};

export const getDiscountedDealData = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'discountedDealReducer' },
      payload: true,
    });

    const apiResponse = await axios
      .get(`${API_ROOT}${GET_DISCOUNTED_DEALS_API}`, { params: props })
      .then(res => {
        dispatch({
          type: t.GET_DISCOUNTED_DEALS,
          meta: { reducer: 'discountedDealReducer' },
          payload: {
            data: res.data.data,
          },
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'discountedDealReducer' },
          payload: err.response.data.message,
        });
      });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'discountedDealReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'discountedDealReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'discountedDealReducer' },
      payload: error,
    });
  }
};

export const getBreadCrumData = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'breadcrumReducer' },
      payload: true,
    });

    const apiResponse = await axios
      .post(API_ROOT + GET_BREADCRUMS_API, DataHelper(props), {
        headers: headers,
      })
      .then(res => {
        dispatch({
          type: t.GET_BREADCRUM_DATA,
          meta: { reducer: 'breadcrumReducer' },
          payload: {
            data: res.data.data.breadCrum,
          },
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'breadcrumReducer' },
          payload: err.response.data.message,
        });
      });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'breadcrumReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'breadcrumReducer' },
      payload: error?.response?.data?.message || '',
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'breadcrumReducer' },
      payload: error,
    });
  }
};

export const getUserFavouritesData = props => async dispatch => {
  try {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'searchReducer' },
      payload: true,
    });

    await axios
      .get(API_ROOT + USER_ALL_WISHLIST, { params: props })
      .then(res => {
        dispatch({
          type: t.GET_USER_FAVOURITES_DATA,
          meta: { reducer: 'searchReducer' },
          payload: { data: res?.data?.data },
        });
      })
      .catch(err => {
        dispatch({
          type: t.ERROR,
          meta: { reducer: 'searchReducer' },
          payload: err.response?.data?.message,
        });
      });

    dispatch({
      type: t.LOADING,
      meta: { reducer: 'searchReducer' },
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: t.LOADING,
      meta: { reducer: 'searchReducer' },
      payload: false,
    });
    dispatch({
      type: t.ERROR,
      meta: { reducer: 'searchReducer' },
      payload: error,
    });
  }
};
