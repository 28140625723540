import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import HomepageSearchBox from '@/components/packages/homepage-search-box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ClassNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    '& .MuiInputBase-root': {
      //textAlign: 'left!important',
      width: '100%',
    },

    '& .MuiInputBase-input': {
      fontFamily: 'Open Sans, sans-serif',
      display: 'flex!important',
      color: '#171717',
      fontSize: '13px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        fontSize: '11px',
        paddingLeft: '10px',
      },
    },
  },
  show: {
    display: 'block',
    position: 'relative',
  },
  hide: {
    display: 'none',
  },
}));

export default function SearchInput(props) {
  const classes = useStyles();
  const {
    width,
    height,
    style,
    placeholder,
    homepageSearchBox,
    handleChange,
    onSelectSearch,
    selectedCities,
    handleOpen,
    handleClickAway,
    is_open,
    is_mobile,
    propertyDetailsClick,
    keyword,
    module_type,
    name,
  } = props;

  useEffect(() => {
    return;
  }, [homepageSearchBox]);
  return (
    <Fragment>
      <Paper className={classes.root} square={true}>
        <InputBase
          placeholder={placeholder}
          width={width}
          height={height}
          style={style}
          onClick={handleOpen}
          onChange={handleChange}
          name={name}
          autoComplete="off"
        />
        {!is_mobile && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway ? handleClickAway : () => void 0}>
            <div
              className={ClassNames([is_open ? classes.show : classes.hide])}>
              <HomepageSearchBox
                selectedCities={selectedCities}
                popularPartData={homepageSearchBox?.popularPart}
                localitiesData={homepageSearchBox?.localities}
                sublocalitiesData={homepageSearchBox?.sub_localities}
                propertiesData={homepageSearchBox?.properties}
                developersData={homepageSearchBox?.developers}
                close_search={handleClickAway}
                onSelectSearch={onSelectSearch}
                mobile={is_mobile}
                propertyDetailsClick={propertyDetailsClick}
                module_type={module_type}
                keyword={keyword}
              />
            </div>
          </ClickAwayListener>
        )}
      </Paper>
    </Fragment>
  );
}
