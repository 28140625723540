/**
 * A centralized module for property search filter functionality. It reads the search filter state from
 * encoded query parameter and generates the same from its internal state. It exports any entities
 * to help with using search filter functionality in any component.
 *
 * @module Hooks/useSearchFilter
 */
import { useRouter } from 'next/router';
import { useState } from 'react';
import base from 'base-x';
import { getSearchPageURL, searchURLType } from '@/components/util';

// base62 object with encoding/decoding methods
const base62 = base(
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
);

export const KEY_FILTER_QUERY = 'f';

/**
 * A custom hook to get/update properties search filter state. It attempts to initialize search filter
 * from the encoded query parameter value otherwise creates filter with default settings. It returns an object containing
 * a settings proxy object which can be used directly for read/update filter state (no setState function is provided).
 * If the {@link initializer} is provided, it is used before the query parameter initialization.
 *
 * @param {function|Object} initializer an object exported from exportFilter or a function with initial state parameter
 * @returns search filter object with settings and other methods
 */
function useSearchFilter(initializer) {
  const router = useRouter();
  // Internal search filter initialized state
  const [state, setState] = useState(() =>
    initializeState(router.query[KEY_FILTER_QUERY], initializer)
  );
  // preState contains most recent filter state captured before re-rendering. For internal use only.
  const preState = Object.assign({}, state);
  // Proxy object for internal state with getters/setters properties for conveninence.
  // This object allows modification of search filter's internal state using simple
  // assignment expression instead of any setState function.
  const settings = createStateProxy(preState, setState);
  return {
    settings, // filter settings proxy object
    // Exports filter state into an object to use later for initialization of search filter
    exportFilter: () => exportState(preState),
    // Navigate to search page with most recent search filter state.
    // When shallow is true, instead of navigating to search page it performs shallow search assuming active page is
    // search page.
    search: (shallow = false) => {
      const encodedFilterQuery = stateToQuery(preState);
      const url = getSearchPageURL(
        searchURLType.search,
        settings.module,
        '',
        encodedFilterQuery
      );
      if (!shallow) {
        window.location.href = url;
      } else {
        shallowSearch(url);
      }
    },
    // Getter for the search page url based on the most recent search filter state
    get searchUrl() {
      const encodedFilterQuery = stateToQuery(preState);
      return getSearchPageURL(
        searchURLType.search,
        settings.module,
        '',
        encodedFilterQuery
      );
    },
    // Reset function to clear the search filter state to default settings and
    // if initializer is provided, it is used for initialization.
    reset: innerInitializer => {
      // Reset Pre-State
      resetState(newState => {
        Object.keys(preState).forEach(key => delete preState[key]);
        Object.assign(preState, newState);
      }, innerInitializer);
      // Reset State
      resetState(setState, innerInitializer);
    },
    // Creates the copy of most recent search filter state object
    toObject: () => Object.assign({}, preState),
  };
}

/**
 * Initializes the search filter internal state using given initializer and encoded filter state value.
 *
 * @param {string|null} filterQuery base62 encoded search filter state
 * @param {function|object} initializer an object exported from exportState or a function with initial state parameter
 * @returns initialized search filter state object
 */
function initializeState(filterQuery, initializer) {
  // Default empty search filter state
  let state = {
    city_name: 'Mumbai',
    city_id: 1,
    module_type: 'buy',
    configuration_count: [],
    params: ['', 'search', ''],
    configuration: [],
    price: [],
    carpet_area: [],
    possession_year: [],
    sale_type: '',
    property_type: [],
    furnishing_status: [],
    lease_type: [],
    builder_rating: [],
    livability_rating: [],
    investment_rating: [],
    recommended: [],
    amenities: [],
    facilities: [],
    bank: [],
    popular_part_id: [],
    location_id: [],
    sublocation_id: [],
    developer_id: [],
  };

  if (initializer && typeof initializer === 'function') {
    // Creating proxy here

    // This mapping object is required so that the keys
    // on left side can be used with proxy object but all
    // operations on them are applied to the search filter state
    // property keys on right side.
    const keyMap = {
      cityName: 'city_name',
      cityId: 'city_id',
      module: 'module_type',
      bhks: 'configuration_count',
      unitTypes: 'configuration',
      price: 'price',
      carpetArea: 'carpet_area',
      possessionYears: 'possession_year',
      saleType: 'sale_type',
      propertyTypes: 'property_type',
      furnishingStatus: 'furnishing_status',
      leaseType: 'lease_type',
      builderRatings: 'builder_rating',
      livabilityRatings: 'livability_rating',
      investmentRatings: 'investment_rating',
      recommendedRatings: 'recommended',
      amenities: 'amenities',
      facilities: 'facilities',
      banks: 'bank',
      popularParts: 'popular_part_id',
      locations: 'location_id',
      subLocations: 'sublocation_id',
      developers: 'developer_id',
    };
    const proxy = new Proxy(state, {
      // Intercepting all property access invokations
      get(target, property, receiver) {
        const resolvedProperty =
          property in keyMap ? keyMap[property] : property;
        return Reflect.get(target, resolvedProperty, receiver);
      },
      // Intercepting all property modification invokations
      set(target, property, value, receiver) {
        const resolvedProperty =
          property in keyMap ? keyMap[property] : property;
        return Reflect.set(target, resolvedProperty, value, receiver);
      },
    });
    initializer(proxy);
  } else if (initializer && typeof initializer === 'object') {
    Object.keys(initializer).forEach(key => {
      if (!(key in state)) return;
      state[key] = initializer[key];
    });
  }
  if (filterQuery) {
    // Constructing search filter state from filterQuery and using it
    // for initialization.
    Object.assign(state, parseEncodedQuery(filterQuery));
  }
  return state;
}

/**
 * Creates a wrapper object for the search filter's internal state and provides
 * convenient getter/setters for updating it. The returned wrapper also captures
 * the most recent search filter state on each state s before the component re-renders.
 *
 * @param {object} preState Search filter prestate storing most recent state
 * @param {Function} setState setState function associated with search filter internal state
 * @returns Wrapper object for internal search filter state
 */
function createStateProxy(preState, setState) {
  // Function for updating array values in filter state
  const setValue = (key, value) => {
    if (!Array.isArray(value)) throw TypeError();
    preState[key] = [...new Set(value)];
    setState(prev => ({ ...prev, [key]: [...new Set(value)] }));
  };

  return {
    get cityName() {
      return preState['city_name'];
    },
    set cityName(value) {
      preState['city_name'] = value;
      setState(prev => ({ ...prev, city_name: value }));
    },
    get cityId() {
      return preState['city_id'];
    },
    set cityId(value) {
      preState['city_id'] = value;
      setState(prev => ({ ...prev, city_id: value }));
    },
    get module() {
      return preState['module_type'];
    },
    set module(value) {
      if (typeof value !== 'string') throw new TypeError();
      preState['module_type'] = value;
      setState(prev => ({ ...prev, module_type: value }));
    },
    set price(value) {
      if (!Array.isArray(value)) throw new TypeError();
      let [min, max] = [Math.max(value[0], 0), Math.max(value[1], 0)];
      preState['price'] = min > 0 || max > 0 ? [min, max] : [];
      setState(prev => ({
        ...prev,
        price: min > 0 || max > 0 ? [min, max] : [],
      }));
    },
    get price() {
      return preState['price'] ?? [];
    },
    get minPrice() {
      return preState['price'][0] ?? 0;
    },
    get maxPrice() {
      return preState['price'][1] ?? 0;
    },
    set carpetArea(value) {
      if (!Array.isArray(value)) throw new TypeError();
      preState['carpet_area'] = [value[0], value[1]];
      setState(prev => ({ ...prev, carpet_area: [value[0], value[1]] }));
    },
    get carpetArea() {
      return preState['carpet_area'] ?? [];
    },
    get minCarpetArea() {
      return preState['carpet_area'][0] ?? 0;
    },
    get maxCarpetArea() {
      return preState['carpet_area'][1] ?? 0;
    },
    get bhks() {
      return preState['configuration_count'];
    },
    set bhks(value) {
      setValue('configuration_count', value);
    },
    get unitTypes() {
      return preState['configuration'];
    },
    set unitTypes(value) {
      setValue('configuration', value);
    },
    get possessionYears() {
      return preState['possession_year'];
    },
    set possessionYears(value) {
      setValue('possession_year', value);
    },
    set saleType(value) {
      if (typeof value !== 'string') throw new TypeError();
      preState['sale_type'] = value;
      setState(prev => ({ ...prev, sale_type: value }));
    },
    get saleType() {
      return preState['sale_type'];
    },
    set propertyTypes(value) {
      setValue('property_type', value);
    },
    get propertyTypes() {
      return preState['property_type'];
    },
    set furnishingStatus(value) {
      setValue('furnishing_status', value);
    },
    get furnishingStatus() {
      return preState['furnishing_status'];
    },
    set leaseType(value) {
      setValue('lease_type', value);
    },
    get leaseType() {
      return preState['lease_type'];
    },
    set builderRatings(value) {
      setValue('builder_rating', value);
    },
    get builderRatings() {
      return preState['builder_rating'];
    },
    set livabilityRatings(value) {
      setValue('livability_rating', value);
    },
    get livabilityRatings() {
      return preState['livability_rating'];
    },
    set investmentRatings(value) {
      setValue('investment_rating', value);
    },
    get investmentRatings() {
      return preState['investment_rating'];
    },
    set recommendedRatings(value) {
      setValue('recommended', value);
    },
    get recommendedRatings() {
      return preState['recommended'];
    },
    set amenities(value) {
      setValue('amenities', value);
    },
    get amenities() {
      return preState['amenities'];
    },
    set facilities(value) {
      setValue('facilities', value);
    },
    get facilities() {
      return preState['facilities'];
    },
    set banks(value) {
      setValue('bank', value);
    },
    get banks() {
      return preState['bank'];
    },
    set popularParts(value) {
      setValue('popular_part_id', value);
    },
    get popularParts() {
      return preState['popular_part_id'];
    },
    set locations(value) {
      setValue('location_id', value);
    },
    get locations() {
      return preState['location_id'];
    },
    set subLocations(value) {
      setValue('sublocation_id', value);
    },
    get subLocations() {
      return preState['sublocation_id'];
    },
    set developers(value) {
      setValue('developer_id', value);
    },
    get developers() {
      return preState['developer_id'];
    },
  };
}

/**
 * Clears the search filter state to default settings and initialize it
 * with given intializer (if present).
 *
 * @param {Function} setState  setState function associated with search filter internal state
 * @param {Function|object} initializer an object exported from exportState or a function with initial state parameter
 */
function resetState(setState, initializer) {
  setState(initializeState(null, initializer));
}

/**
 * Exports the given internal state of search filter as a plain object.
 * It also transforms & discards some values and keys in the exported object.
 * The exported object can be used to persist filter state for later
 * initialization.
 *
 * @param {object} state Internal search filter state
 * @returns {object} Transformed search filter state object
 */
function exportState(state) {
  const stateObj = Object.assign({}, state);
  stateObj['popular_part_id'] = stateObj['popular_part_id'].map(i => i.id);
  stateObj['location_id'] = stateObj['location_id'].map(i => i.id);
  stateObj['sublocation_id'] = stateObj['sublocation_id'].map(i => i.id);
  stateObj['developer_id'] = stateObj['developer_id'].map(i => i.id);
  stateObj['price_filter'] = stateObj['price'].length > 0;
  stateObj['carpet_filter'] = stateObj['carpet_area'].length > 0;
  stateObj['amenities_facilities'] = stateObj['amenities'].concat(
    stateObj['facilities']
  );
  delete stateObj['amenities'];
  delete stateObj['facilities'];
  if (stateObj['module_type'] !== 'buy') {
    delete stateObj['possession_year'];
    delete stateObj['sale_type'];
  }
  return stateObj;
}

/**
 * Serializes the given search filter internal state into the base62 encoded string.
 * While serializing, it discards any empty array values in the search filter state.
 *
 * @param {object} state Internal search filter state
 * @returns {string} base62 encoded filter state string
 */
function stateToQuery(state) {
  const stateObj = Object.assign({}, state);
  for (const key of Object.keys(state)) {
    if (Array.isArray(stateObj[key]) && stateObj[key].length === 0) {
      delete stateObj[key]; // Get rid of empty array values
    }
  }
  const encoder = new TextEncoder();
  return base62.encode(encoder.encode(JSON.stringify(stateObj)));
}

/**
 * Deserializes the given base62 encoded filter state string into
 * internal search filter state object.
 *
 * @param {string} encodedQuery base62 encoded filter state string
 * @returns {object} internal search filter state object
 */
function parseEncodedQuery(encodedQuery) {
  try {
    const jsonString = new TextDecoder('utf-8', { fatal: true }).decode(
      base62.decode(encodedQuery)
    );
    return JSON.parse(jsonString);
  } catch (ex) {
    return null;
  }
}

/**
 * Performs shallow search navigation by adding/updating only search filter query
 * parameter in current page URL instead of refreshing the whole page.
 * It **replaces** the current url instead of adding new in the browser history.
 * So browser's back button will navigate to same previous page as before.
 *
 * @param {string} encodedFilterQuery Serialized string from most recent search filter state
 */
function shallowSearch(url) {
  // const url = new URL(window.location.href);
  // url.searchParams.set(KEY_FILTER_QUERY, encodedFilterQuery);
  history.replaceState({ path: url }, '', url);
}

export default useSearchFilter;

export const decodeSearchFilterQueryToObject = parseEncodedQuery;
